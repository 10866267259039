import React from "react";
import { withRouter, Link } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";

/*import { BodyTwoGrid } from "../components/WebsiteElements";*/
import Constants from "../helpers/Constants";
import * as Gtag from "../helpers/Gtag";

// Google Analytics
import ReactGA from "react-ga";
import { buttonTracking } from "../helpers/GaPolicy";

// Support for IE11
(function () {
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();

function onMenuHoverIn(id) {
  let ele = document.getElementById(id);
  if (ele) {
    ele.classList.add("nav-subMenu-isOpened");
  }
}
function onMenuHoverOut(id) {
  let ele = document.getElementById(id);
  if (ele) {
    ele.classList.remove("nav-subMenu-isOpened");
  }
}

function onSideMenuClick(id) {
  let menus = ["product", "features", "solutions", "cases"];

  for (let item of menus) {
    let ele = document.getElementById(item);
    let arrowEle = document.getElementById(item + "-arrow");
    if (ele) {
      if (item === id) {
        if (ele.classList.contains("nav-subMenu-isClicked")) {
          ele.classList.remove("nav-subMenu-isClicked");
          ele.classList.add("nav-subMenu");
          arrowEle.attributes["transition-state"].value = "on";
        } else {
          ele.classList.add("nav-subMenu-isClicked");
          ele.classList.remove("nav-subMenu");
          arrowEle.attributes["transition-state"].value = "off";
        }
      } else {
        ele.classList.remove("nav-subMenu-isClicked");
        ele.classList.add("nav-subMenu");
        arrowEle.attributes["transition-state"].value = "on";
      }
    }
  }
}

function onSideMenuCollapse() {
  let menus = ["product", "features", "solutions", "cases"];
  for (let item of menus) {
    let ele = document.getElementById(item);
    let arrowEle = document.getElementById(item + "-arrow");
    if (ele.classList.contains("nav-subMenu-isClicked")) {
      ele.classList.remove("nav-subMenu-isClicked");
      ele.classList.add("nav-subMenu");
      arrowEle.attributes["transition-state"].value = "on";
    }
  }
}

const MenuList = {
  product: [
    {
      label: "그로잉세일즈 포스",
      link: "/pos",
    },
    {
      label: "그로잉세일즈 리테일",
      link: "/retail",
    },
    {
      label: "그로잉세일즈 미니",
      link: "/mini",
    },
    {
      label: "사용 시작방법",
      link: "/getting-started",
    },
  ],
  features: [
    {
      label: "CRM",
      link: "/features/marketing",
    },
    {
      label: "재고관리",
      link: "/features/stock",
    },
    {
      label: "카페24 연동",
      link: "/features/cafe24",
    },
  ],
  solutions: [
    {
      label: "키오스크",
      link: "/features/kiosk",
    },
    {
      label: "테이블 오더",
      link: "/features/table-order",
    },
    {
      label: "KDS",
      link: "/features/kds",
    },
    {
      label: "웨이팅",
      link: "/features/waiting",
    },
  ],
  cases: [
    {
      label: "외식업",
      link: "/cases?type=food",
    },
    {
      label: "소매업",
      link: "/cases?type=retail",
    },
    {
      label: "서비스업",
      link: "/cases?type=service",
    },
    {
      label: "프랜차이즈",
      link: "/cases?type=franchise",
    },
  ],
};

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { sidebarOpen: false, isWide: false };
    this.handleStorePress = this.handleStorePress.bind(this);
    this.handleSigninPress = this.handleSigninPress.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = (e) => {
    let isWide = window.innerWidth >= 760;
    if (this.state.isWide !== isWide) {
      this.setState({
        isWide,
        sidebarOpen: isWide ? false : this.state.sidebarOpen,
      });
    }
    if (isWide) {
      onSideMenuCollapse();
    }
  };

  handleSigninPress() {
    window.open(Constants.DASHBOARD_URL);
    this.setState({ sidebarOpen: false });
    buttonTracking("nav", "move", "signin");
  }

  handleStorePress() {
    window.open(Constants.STORE);
    this.setState({ sidebarOpen: false });
    buttonTracking("global", "move", "store");
  }

  handleMenuClick = (link, isExternal = false) => {
    if (isExternal) {
      window.open(link);
    } else {
      this.setState({ sidebarOpen: false });
      buttonTracking("nav", "move", link);
      this.props.history.push(link);
    }
  };

  render() {
    return (
      <>
        <div className="navbar navbar-scroll">
          <Link to="/">
            <img
              alt="logo"
              className="nav-logo"
              src={require("../img/brand/logo.png")}
            />
          </Link>
          <div className="nav-center-block">
            <ul
              className={
                this.state.sidebarOpen ? "nav-links active" : "nav-links"
              }
            >
              <li
                onMouseEnter={
                  !this.state.sidebarOpen
                    ? () => onMenuHoverIn("product")
                    : () => null
                }
                onMouseLeave={
                  !this.state.sidebarOpen
                    ? () => onMenuHoverOut("product")
                    : () => null
                }
                className="nav-item bold"
              >
                <Link
                  onClick={
                    this.state.sidebarOpen
                      ? () => onSideMenuClick("product")
                      : () => null
                  }
                  to={window.location.pathname}
                  className="nav-link d-flex align-items-center"
                >
                  제품
                  <div
                    transition-state="on"
                    id="product-arrow"
                    className="ml-auto"
                  >
                    <div className="accordion-arrow" />
                  </div>
                </Link>
                <div id="product" className="nav-subMenu">
                  {MenuList.product.map((item) => (
                    <li
                      className={
                        item.isNew
                          ? "nav-subMenu-item nav-subMenu-item-isNew"
                          : "nav-subMenu-item"
                      }
                    >
                      <a onClick={() => this.handleMenuClick(item.link)}>
                        {item.label}
                      </a>
                    </li>
                  ))}
                </div>
              </li>
              <li
                onMouseEnter={
                  !this.state.sidebarOpen
                    ? () => onMenuHoverIn("features")
                    : () => null
                }
                onMouseLeave={
                  !this.state.sidebarOpen
                    ? () => onMenuHoverOut("features")
                    : () => null
                }
                className="nav-item bold"
              >
                <Link
                  onClick={
                    this.state.sidebarOpen
                      ? () => onSideMenuClick("features")
                      : () => null
                  }
                  to={window.location.pathname}
                  className="nav-link d-flex align-items-center"
                >
                  주요 기능들
                  <div
                    transition-state="on"
                    id="features-arrow"
                    className="ml-auto"
                  >
                    <div className="accordion-arrow" />
                  </div>
                </Link>
                <div id="features" className="nav-subMenu">
                  {MenuList.features.map((item) => (
                    <li
                      className={
                        item.isNew
                          ? "nav-subMenu-item nav-subMenu-item-isNew"
                          : "nav-subMenu-item"
                      }
                      aria-hidden={item.disabled}
                    >
                      <a
                        onClick={
                          item.disabled
                            ? () => null
                            : () =>
                                this.handleMenuClick(item.link, item.external)
                        }
                      >
                        {item.label}
                      </a>
                    </li>
                  ))}
                </div>
              </li>
              <li
                onMouseEnter={
                  !this.state.sidebarOpen
                    ? () => onMenuHoverIn("solutions")
                    : () => null
                }
                onMouseLeave={
                  !this.state.sidebarOpen
                    ? () => onMenuHoverOut("solutions")
                    : () => null
                }
                className="nav-item bold"
              >
                <Link
                  onClick={
                    this.state.sidebarOpen
                      ? () => onSideMenuClick("solutions")
                      : () => null
                  }
                  to={window.location.pathname}
                  className="nav-link d-flex align-items-center"
                >
                  부가서비스
                  <div
                    transition-state="on"
                    id="solutions-arrow"
                    className="ml-auto"
                  >
                    <div className="accordion-arrow" />
                  </div>
                </Link>
                <div id="solutions" className="nav-subMenu">
                  {MenuList.solutions.map((item) => (
                    <li
                      className={
                        item.isNew
                          ? "nav-subMenu-item nav-subMenu-item-isNew"
                          : "nav-subMenu-item"
                      }
                      aria-hidden={item.disabled}
                    >
                      <a
                        onClick={
                          item.disabled
                            ? () => null
                            : () =>
                                this.handleMenuClick(item.link, item.external)
                        }
                      >
                        {item.label}
                      </a>
                    </li>
                  ))}
                </div>
              </li>
              <li
                onMouseEnter={
                  !this.state.sidebarOpen
                    ? () => onMenuHoverIn("cases")
                    : () => null
                }
                onMouseLeave={
                  !this.state.sidebarOpen
                    ? () => onMenuHoverOut("cases")
                    : () => null
                }
                className="nav-item bold"
              >
                <Link
                  onClick={
                    this.state.sidebarOpen
                      ? () => onSideMenuClick("cases")
                      : () => null
                  }
                  to={window.location.pathname}
                  className="nav-link d-flex align-items-center"
                >
                  업종별 사용
                  <div
                    transition-state="on"
                    id="cases-arrow"
                    className="ml-auto"
                  >
                    <div className="accordion-arrow" />
                  </div>
                </Link>
                <div id="cases" className="nav-subMenu">
                  {MenuList.cases.map((item) => (
                    <li
                      aria-hidden={item.disabled}
                      className="nav-subMenu-item"
                    >
                      <a
                        onClick={
                          item.disabled
                            ? () => null
                            : () => this.handleMenuClick(item.link)
                        }
                      >
                        {item.label}
                      </a>
                    </li>
                  ))}
                </div>
              </li>
              <li id="store" className="nav-item bold">
                <Link
                  to={window.location.pathname}
                  onClick={this.handleStorePress}
                  className="nav-link"
                >
                  스토어
                </Link>
              </li>
              <li id="login" className="nav-item nav-item-sidebar-show bold">
                <Link
                  onClick={this.handleSigninPress}
                  className="nav-link nav-link-blue"
                >
                  로그인
                </Link>
              </li>
            </ul>
          </div>
          <li id="signin" className="nav-item-right-item bold">
            <Link
              onClick={this.handleSigninPress}
              className="nav-link nav-link-blue"
            >
              로그인
            </Link>
          </li>
          <div
            onClick={() =>
              this.setState({ sidebarOpen: !this.state.sidebarOpen })
            }
            className="nav-icon"
          >
            {this.state.sidebarOpen ? <FiX /> : <FiMenu />}
          </div>
        </div>
        <div
          className={this.state.sidebarOpen ? "overlay-bg" : "display-none"}
        />
      </>
    );
  }
}

export default withRouter(Navbar);
