import React from "react";

import { withRouter } from "react-router-dom";
import Slider from "react-slick";

import {
  SubHeaderTwoColumnLeft,
  FeatureBlock,
  CaseOneColumn,
  CaseDetail,
  FeatureDetail,
  QandABlock,
} from "../components/Elements";
import Constants from "../helpers/Constants";

// Google Analytics
import ReactGA from "react-ga";
import { buttonTracking } from "../helpers/GaPolicy";
import FAQs from "../helpers/FAQs";

// Support for IE11
(function () {
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();

const REVIEWS = [
  {
    logo: "standoil",
    title: "오프라인 매니저",
    description: (
      <div>
        그로잉세일즈를 통해 멤버십 관리 뿐 아니라 포인트까지 자사몰과 동일하게
        적립/사용할 수 있게 되었어요.
      </div>
    ),
  },
  {
    logo: "artish",
    title: "마케팅 팀장",
    description: (
      <div>
        그로잉세일즈의 온/오프라인 고객 통합을 통해 아티쉬 고객들에게 동일한
        구매경험을 제공할 수 있게 되었습니다.
      </div>
    ),
  },
  {
    logo: "narka",
    title: "이사",
    description: (
      <div>
        온/오프라인 통합의 시작을 부담없이 시작할 수 있었으며, 외국 손님을 위한
        다양한 기능도 있어서 편리합니다.
      </div>
    ),
  },
];

const SLIDE_IMAGES = [
  { image: "cafe24-standoil1.png" },
  { image: "cafe24-standoil2.png" },
  { image: "cafe24-standoil3.png" },
  { image: "cafe24-standoil4.png" },
  { image: "cafe24-standoil5.png" },
  { image: "cafe24-standoil6.png" },
];

const SLIDER_SETTINGS = {
  infinite: true,
  centerMode: true,
  swipeToSlide: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 718,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

const onScrollHandler = function () {
  const pointElement = document.querySelector("#customerRetrieve");
  const couponElement = document.querySelector("#couponUse");
  let scrollPosition = pointElement.getBoundingClientRect();
  let couponScrollPosition = couponElement.getBoundingClientRect();
  // 포인트 조회 이미지 변경
  if (scrollPosition.top - 300 < 0) {
    pointElement.classList.remove("point-scrolled-image");
    pointElement.classList.add("point-scroll-image");
  }
  if (scrollPosition.top - 300 > 0) {
    pointElement.classList.remove("point-scroll-image");
    pointElement.classList.add("point-scrolled-image");
  }
  // 쿠폰 사용 이미지 변경
  if (couponScrollPosition.top - 300 < 0) {
    couponElement.classList.remove("coupon-scrolled-image");
    couponElement.classList.add("coupon-scroll-image");
  }
  if (couponScrollPosition.top - 300 > 0) {
    couponElement.classList.remove("coupon-scroll-image");
    couponElement.classList.add("coupon-scrolled-image");
  }
};

class Cafe24 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pointView: "retrieve" };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", onScrollHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    window.removeEventListener("scroll", onScrollHandler);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleAppStoreClick = (platform) => {
    if (platform === "ios") {
      window.open(Constants.APPSTORE_IOS);
      buttonTracking("pos", "download", "iOS");
    }
    if (platform === "android") {
      window.open(Constants.APPSTORE_ANDROID);
      buttonTracking("pos", "download", "android");
    }
  };

  handleInventoryDetail = () => {
    this.props.history.push("/features/stock");
    buttonTracking("retail", "move", "stock");
  };

  handlePrevious() {
    this.referSlider.slickPrev();
  }

  handleNext() {
    this.referSlider.slickNext();
  }

  handleSignUpPress = () => {
    buttonTracking("pos", "move", "signup");
    window.open(Constants.SIGNUP_URL);
  };

  handlePointViewChange = (type) => {
    this.setState({ pointView: type });
  };

  handleCafe24Go = () => {
    window.open(Constants.CAFE24_STORE);
  };

  render() {
    return (
      <div style={{ backgroundColor: "#fff" }}>
        <div id="main-content">
          <div className="section-container">
            <div className="sub-header-wrapper">
              <div className="h2 text-align-center">
                자사몰 연동으로
                <br />
                <div className="blue-color">온・오프라인 통합</div>
              </div>
            </div>
            <img
              alt="main"
              src={require("../img/cafe24/2025-cafe24-main.png")}
              className="w-100 no-event"
            />
          </div>
          <div className="pl-pr-line">
            <div className="bg-ultra-blue" style={{ borderRadius: "32px" }}>
              <div className="section-container">
                <div className="title-wrapper">
                  <div
                    className="sub-title text-align-center"
                    style={{ color: "#003682" }}
                  >
                    운영 중이신 자사몰이 있으시다면
                    <br />
                    지금 무료로 연동해보세요
                  </div>
                  <div className="flex-center">
                    <div
                      onClick={this.handleCafe24Go}
                      className="label-button label-button-blue"
                    >
                      자세히보기
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-container">
            <div className="sub-header-wrapper">
              <div
                className="sub-title text-align-center"
                style={{ color: "#A4C9FD" }}
              >
                이미 많은 브랜드에서 <br />
                온・오프라인 통합을 경험하고 있어요
              </div>
            </div>
            <div className="landing-main-client" />
          </div>

          {/* body 1 */}
          <div className="custom-bg">
            <div className="_content">
              <div className="sub-header-wrapper">
                <div className="sub-title">
                  통합된 브랜드 경험으로
                  <br />
                  고객을 맞이해 보세요.
                </div>
                <div className="sub-description">
                  고객이 어느 곳을 방문하든, 동일한 브랜드 경험을 제공할 수
                  있습니다.
                </div>
              </div>

              <div className="slides-container">
                <img
                  src={require("../img/cafe24/cafe24-body-1-1.png")}
                  className="w-100 prevent-image-down"
                />
              </div>
            </div>
            <div>
              <div className="_content">
                <SubHeaderTwoColumnLeft
                  isRight
                  textArea={
                    <div className="product-feature product-feature--left-padding">
                      <p id="scrollPosition"></p>
                      <div className="h3">
                        {"오프라인 매장에서도"}
                        <br />
                        {"온라인 가입고객 조회하기"}
                      </div>
                      <p id="textComp">
                        {
                          "운영하시는 매장에서 브랜드 웹사이트에서 가입한 고객의 정보를 조회할 수 있습니다."
                        }
                      </p>
                    </div>
                  }
                  imageArea={
                    <div
                      id="customerRetrieve"
                      className="cafe24-point-image point-scrolled-image"
                    />
                  }
                />
                <SubHeaderTwoColumnLeft
                  hasMargin
                  textArea={
                    <div className="product-feature product-feature--right-padding">
                      <div className="h3">
                        {"온라인 적립・할인혜택을"}
                        <br />
                        {"오프라인에서 그대로"}
                      </div>
                      <p>
                        {
                          "어디서든 동일한 적립정책으로 고객이 온라인, 오프라인 매장 어디서 구매하든 동일한 혜택을 받을 수 있게 운영이 가능합니다."
                        }
                      </p>
                      <div
                        onClick={() => this.handlePointViewChange("retrieve")}
                        className="mt-mb-small d-flex align-items-center sub-content"
                      >
                        <div className="h5 bold blue-color mb-0">
                          포인트 조회
                        </div>
                        <div className="arrow-right ml-line" />
                      </div>
                      <div
                        onClick={() => this.handlePointViewChange("use")}
                        className="mt-mb-small d-flex align-items-center sub-content"
                      >
                        <div className="h5 bold blue-color mb-0">
                          포인트 사용
                        </div>
                        <div className="arrow-right ml-line" />
                      </div>
                    </div>
                  }
                  imageArea={
                    <picture>
                      <img
                        className="max-width-100 prevent-image-down"
                        srcset={
                          this.state.pointView === "retrieve"
                            ? require("../img/cafe24/cafe24-body2-2-1.png")
                            : require("../img/cafe24/cafe24-body2-2-2.png")
                        }
                      />
                    </picture>
                  }
                />
                <SubHeaderTwoColumnLeft
                  isRight
                  hasMargin
                  textArea={
                    <div className="product-feature product-feature--left-padding">
                      <div className="h3">
                        {"온라인 쿠폰조회는 물론,"}
                        <br />
                        {"클릭 한번으로 사용까지"}
                      </div>
                      <p>
                        {
                          "온라인 사이트에서 발급된 쿠폰이더라도 고객 조회시 보유쿠폰을 함께 보여드려 그로잉세일즈 포스에서 사용이 가능합니다."
                        }
                      </p>
                    </div>
                  }
                  imageArea={
                    <div
                      id="couponUse"
                      className="cafe24-point-image point-scrolled-image"
                    />
                  }
                />
                <SubHeaderTwoColumnLeft
                  hasMargin
                  isLast
                  textArea={
                    <div className="product-feature product-feature--right-padding">
                      <div className="h3">
                        {"포인트 적립과 사용내역은"}
                        <br />
                        {"무료 실시간 알림톡으로"}
                      </div>
                      <p>
                        {
                          "오프라인 매장에서 포인트 적립 혹은 사용을 한 고객님에게 무료로 실시간 알림톡을 발송하여 매장을 나가실때까지 브랜드 경험을 지켜드려요."
                        }
                      </p>
                      <div className="mt-line">
                        <FeatureBlock desc={"비즈니스 채널 연동지원"} />
                        <FeatureBlock desc={"커스텀 템플릿 제공"} />
                      </div>
                    </div>
                  }
                  imageArea={
                    <picture>
                      <img
                        className="max-width-100 prevent-image-down"
                        srcset={require("../img/cafe24/cafe24-body2-4.png")}
                      />
                    </picture>
                  }
                />
              </div>
            </div>
          </div>
          <div className="section-container">
            <div className="sub-header-wrapper">
              <div className="sub-title">
                엑셀 작업없이
                <br />
                클릭 한 번으로
                <br />
                고객 데이터 통합조회
              </div>
              <div className="sub-description">
                온・오프라인 주문내역 뿐 아니라, 고객이 보유한 혜택정보도 한
                눈에
              </div>
            </div>
            <div className="vertical-wrapper">
              <div className="feature-wrapper">
                <div className="case-comp">
                  <img
                    alt="main"
                    src={require("../img/cafe24/cafe24-order-integration.png")}
                    className="w-100 no-event"
                  />
                </div>
                <div className="case-comp">
                  <img
                    alt="main"
                    src={require("../img/cafe24/2025-cafe24-orderlist.png")}
                    className="w-100 no-event"
                  />
                </div>
              </div>
              <div className="feature-wrapper">
                <div className="case-comp case-comp-one case-comp-blue">
                  <div className="cafe24-coupon" />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-ultra-green">
            <div className="section-container">
              <div className="sub-header-wrapper">
                <p className="sub-label sub-label-green">데이터 연동</p>
                <div className="sub-title">
                  어디서든
                  <br />이 모든게 실시간 동기화
                </div>
                <div className="sub-description">
                  모든 정보의 실시간 동기화로 정확한 데이터 조회가 가능
                </div>
              </div>
              <div className="case-comp case-comp-one">
                <img
                  alt="main"
                  src={require("../img/cafe24/cafe24-data.png")}
                  className="w-100 no-event"
                />
              </div>
            </div>
          </div>
          <div className="bg-ultra-yellow">
            <div className="section-container">
              <div className="sub-header-wrapper">
                <p className="sub-label" style={{ backgroundColor: "#876a00" }}>
                  등급관리
                </p>
                <div className="sub-title">
                  통합 주문내역 기반
                  <br />
                  자동 등급제 도입하기
                </div>
                <div className="sub-description">
                  고객의 주문내역을 분석하여 자동으로 등급을 업데이트 해드려요
                </div>
              </div>
              <div
                className="case-comp case-comp-one"
                style={{ backgroundColor: "#fae7a4", borderRadius: "20px" }}
              >
                <img
                  alt="main"
                  src={require("../img/cafe24/2025-cafe24-membership.png")}
                  className="w-100 no-event"
                />
              </div>
            </div>
          </div>
          <div className="custom-bg">
            <div className="section-container">
              <div className="sub-header-wrapper">
                <p className="sub-label sub-label-primary">상품・재고</p>
                <div className="sub-title">
                  관리는 한 번만
                  <br />
                  매출은 한 눈에
                </div>
                <div className="sub-description">
                  연동 상품의 통합 매출내역은 물론
                  <br />
                  재고관리까지 한 번에
                </div>
              </div>
              <div className="slides-container">
                <img
                  src={require("../img/cafe24/cafe24-body-product.png")}
                  className="w-100 prevent-image-down"
                />
              </div>
              <div className="feature-wrapper">
                <div
                  className="case-comp"
                  style={{ backgroundColor: "#e9ecef" }}
                >
                  <div className="title-wrapper">
                    <div className="sub-title">
                      두번 관리하는일 없이
                      <br />
                      바로 동기화
                    </div>
                  </div>
                  <img
                    alt="main"
                    src={require("../img/cafe24/cafe24-item-sub1.png")}
                    className="w-100 no-event"
                  />
                </div>
                <div
                  className="case-comp"
                  style={{ backgroundColor: "#e9ecef" }}
                >
                  <div className="title-wrapper">
                    <div className="sub-title">
                      연동된 상품은
                      <br />
                      통합 리포트 조회까지
                    </div>
                  </div>
                  <img
                    alt="main"
                    src={require("../img/cafe24/cafe24-item-sub2.png")}
                    className="w-100 no-event"
                  />
                </div>
              </div>
            </div>
            <div className="custom-bg bg-primary">
              <div className="section-container">
                <div className="sub-header-wrapper">
                  <div className="sub-title white-color">
                    옴니채널 통합 재고관리
                  </div>
                  <div className="sub-description">
                    자사몰의 실시간 재고현황을 반영한 통합 재고관리가 가능
                  </div>
                </div>
                <div className="feature-wrapper">
                  <div className="case-comp case-comp-one">
                    <img
                      src={require("../img/cafe24/cafe24-stock.png")}
                      className="w-100 prevent-image-down"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="section-container">
              <div className="sub-header-wrapper">
                <div className="sub-title ">
                  외국인 손님이 많은 브랜드를 위한 특별한 기능들 😎
                </div>
              </div>
              <div className="slides-container">
                <div className="narrow-feature">
                  <div className="left">
                    그로잉세일즈
                    <br />
                    웨이팅
                  </div>
                  <div className="right right--waiting" />
                </div>
                <div className="narrow-feature">
                  <div className="left">
                    알리/위챗페이
                    <br />
                    결제연동
                  </div>
                  <div className="right right--ali" />
                </div>
                <div className="narrow-feature">
                  <div className="left">
                    환급서비스
                    <br />
                    TAX REFUND
                  </div>
                  <div className="right right--tax-refund" />
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: "#e3f2fd" }}>
              <div className="section-container">
                <div className="sub-header-wrapper">
                  <p
                    className="sub-label"
                    style={{ backgroundColor: "#0D47A1" }}
                  >
                    도입
                  </p>
                  <div className="sub-title">
                    어떤 매장 운영방식도
                    <br />
                    문제없이 가능해요
                  </div>
                  <div className="sub-description">
                    플래그십스토어, 팝업, 백화점 등 다양한 운영방식을
                    지원합니다.
                  </div>
                </div>
                <div className="vertical-wrapper">
                  <div className="feature-wrapper">
                    <div
                      className="case-comp"
                      style={{ backgroundColor: "#BBDEFB" }}
                    >
                      <div className="title-wrapper">
                        <div className="sub-title" style={{ color: "#0D47A1" }}>
                          플래그십(단일)스토어
                        </div>
                        <div className="description">
                          그로잉세일즈와 연동되는 카드기 설치
                        </div>
                      </div>
                      <img
                        alt="main"
                        src={require("../img/cafe24/2025-cafe24-flagship.png")}
                        className="w-100 no-event"
                      />
                    </div>
                    <div
                      className="case-comp"
                      style={{ backgroundColor: "#BBDEFB" }}
                    >
                      <div className="title-wrapper">
                        <div className="sub-title" style={{ color: "#0D47A1" }}>
                          백화점/몰 사용
                        </div>
                        <div className="description">
                          카드결제없이 주문만 등록 가능
                        </div>
                      </div>
                      <img
                        alt="main"
                        src={require("../img/cafe24/2025-cafe24-mall.png")}
                        className="w-100 no-event"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-container">
              <div className="sub-header-wrapper column-center">
                <div className="sub-title text-align-center">
                  그로잉세일즈 시작하기
                </div>
              </div>
              <div className="feature-wrapper">
                <div className="grid-comp">
                  <div className="title-wrapper">
                    <div className="sub-title">그로잉세일즈 포스</div>
                    <div className="description lightPrimary-color">
                      매장을 새롭게 오픈하신다면
                    </div>
                    <div className="description">
                      <a href={Constants.POS}>더 알아보기</a>
                    </div>
                  </div>
                  <div className="case-comp case-comp-gray">
                    <img
                      alt="main"
                      src={require("../img/landing/2025-pos.png")}
                      className="w-100 no-event"
                    />
                  </div>
                </div>
                <div className="grid-comp">
                  <div className="title-wrapper">
                    <div className="sub-title">그로잉세일즈 미니</div>
                    <div className="description lightPrimary-color">
                      기존 운영중이신 솔루션이 있다면
                    </div>
                    <div className="description">
                      <a href={Constants.MINI}>더 알아보기</a>
                    </div>
                  </div>
                  <div className="case-comp case-comp-gray">
                    <img
                      alt="main"
                      src={require("../img/landing/2025-mini.png")}
                      className="w-100 no-event"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ backgroundColor: "#efeff4" }}>
              <div className="_content">
                <div id="sub-header">
                  <div className="h3">&#128279;&nbsp;도입절차</div>
                  <div className="product-feature">
                    <p>
                      {
                        "주문시 제품의 실시간 재고 확인은 물론, 복잡한 옵션, 고객별 다양한 할인 적용 등 모든 주문 형태가 가능합니다."
                      }
                    </p>
                  </div>
                </div>
                <div className="marketing-container">
                  <FeatureDetail
                    title={"1. 도입문의"}
                    subContent={
                      <>
                        <p className="ml-base">- 상담을 통한 도입문의 안내</p>
                        <a target="blank" href={Constants.HELP_CENTER}>
                          문의하기
                        </a>
                      </>
                    }
                    imageURL={require("../img/cafe24/cafe24-step1.png")}
                  />
                  <FeatureDetail
                    title={"2. 시나리오 협의"}
                    subContent={
                      <>
                        <p className="ml-base">- 연동플랜 및 부가기능 협의</p>
                      </>
                    }
                    imageURL={require("../img/cafe24/cafe24-step2.png")}
                  />
                  <FeatureDetail
                    title={"3. 스토어셋팅 및 출시"}
                    subContent={
                      <>
                        <p className="ml-base">
                          - 스토어 연동 및 서비스셋팅 진행
                        </p>
                        <p className="ml-base">- 영업일 평균 7-10일 소요예정</p>
                      </>
                    }
                    imageURL={require("../img/cafe24/cafe24-step3.png")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-primary">
          <div className="_content">
            <div id="sub-header" style={{ textAlign: "center" }}>
              <div className="h2 white-color">{"자주 묻는 질문들"}</div>
            </div>
            <div className="faq-container">
              <QandABlock data={FAQs.cafe24} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Cafe24);
