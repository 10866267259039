import React from "react";
import { buttonTracking } from "../helpers/GaPolicy";
import Constants from "../helpers/Constants";
import Faq from "react-faq-component";

export const BodyLeft = ({
  title,
  subtitle,
  description,
  imageClass,
  imageId,
  smImageUrl,
  xsimageUrl,
  body,
}) => (
  <div className="body-section body-two-column body-left-column d-flex bg-white bg-custom body-bg-image">
    <div className="content">
      <div className="gr-grid">
        <div className="gr-grid-wrapper">
          <div className="gr-2-column-wrapper body-left-column v-align-center">
            <div className="gr-2-column gr-2-column-right">
              <div
                id="section-header"
                className="header-effect text-align-left"
              >
                <h3 className="h3">
                  {title}
                  {subtitle ? (
                    <React.Fragment>
                      <br /> {subtitle}
                    </React.Fragment>
                  ) : null}
                </h3>
                <h6 className="h6">{description}</h6>
              </div>
              {body}
            </div>
            <div className="gr-2-column " />
          </div>
        </div>
      </div>
    </div>
    <div className="image">
      <div id={imageId} className="flex-align-center">
        <div className="replace-image">
          <picture>
            <source srcset={smImageUrl} media="(min-width: 480px)" />
            <source srcset={xsimageUrl} media="(min-width: 150px)" />
            <img className="max-width-100" src={smImageUrl} />
          </picture>
        </div>
        <div className="image-appro">
          <div className={imageClass} />
        </div>
      </div>
    </div>
  </div>
);

export const BodyRight = ({
  title,
  subtitle,
  description,
  imageClass,
  imageId,
  smImageUrl,
  xsimageUrl,
  body,
}) => (
  <div className="body-section body-two-column d-flex bg-white bg-custom body-bg-image">
    <div className="image">
      <div id={imageId} className="flex-align-center">
        <div className="replace-image">
          <picture>
            <source srcset={smImageUrl} media="(min-width: 480px)" />
            <source srcset={xsimageUrl} media="(min-width: 150px)" />
            <img className="max-width-100" src={smImageUrl} />
          </picture>
        </div>
        <div className="image-appro">
          <div className={imageClass} />
        </div>
      </div>
    </div>

    <div className="content">
      <div className="gr-grid">
        <div className="gr-grid-wrapper">
          <div className="gr-2-column-wrapper v-align-center">
            <div className="gr-2-column " />
            <div className="gr-2-column gr-2-column-left">
              <div
                id="section-header"
                className="header-effect text-align-left"
              >
                <h3 className="h3">
                  {title}
                  {subtitle ? (
                    <React.Fragment>
                      <br /> {subtitle}
                    </React.Fragment>
                  ) : null}
                </h3>
                <h6 className="h6">{description}</h6>
              </div>
              {body}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const ThreeBlockPricing = ({
  title,
  description,
  plans,
  button,
  isWhite,
}) => (
  <React.Fragment>
    <div className="content pb-small">
      <div className="text-align-left" id="section-header">
        <h2 className="h2">{title}</h2>
        <p>{description}</p>
        {button}
      </div>
    </div>
    <div className="content pt-0">
      <div className="gr-3-column-wrapper">
        {plans.map((plan, index) => (
          <div key={index} className="gr-3-column">
            <div
              className={
                isWhite
                  ? "gr-pricing-block gr-pricing-block-white text-align-center"
                  : "gr-pricing-block text-align-center"
              }
            >
              <div
                className={
                  index === plans.length - 1
                    ? "primary-pricing header-effect"
                    : "primary-pricing primary-pricing-border header-effect"
                }
              >
                <h5 className="h5 mb-small">{plan.title}</h5>
                <h1 className="h1 mb-base">{plan.price}</h1>
                <p className="pl-pr-line">{plan.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </React.Fragment>
);

export const FourBlockPricing = ({
  title,
  description,
  plans,
  button,
  isWhite,
}) => (
  <React.Fragment>
    <div className="content pb-small">
      <div className="text-align-left" id="section-header">
        <h2 className="h2">{title}</h2>
        <p>{description}</p>
        {button}
      </div>
    </div>
    <div className="content pt-0">
      <div className="gr-3-column-wrapper">
        {plans.map((plan, index) => (
          <div key={index} className="gr-4-column pl-pr-0">
            <div
              className={
                isWhite
                  ? "gr-pricing-block gr-pricing-block-white text-align-center "
                  : "gr-pricing-block text-align-center"
              }
            >
              <div
                className={
                  index === plans.length - 1
                    ? "primary-pricing header-effect"
                    : index === 1
                    ? "primary-pricing header-effect primary-pricing-even-border"
                    : "primary-pricing primary-pricing-border header-effect"
                }
              >
                <h5 className="h5 mb-small">{plan.title}</h5>
                <h1 className="h1 mb-base">{plan.price}</h1>
                <p className="pl-pr-line">{plan.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </React.Fragment>
);

export const NarrowBodyLeft = ({
  pictures,
  label,
  title,
  subtitle,
  description,
  bodyContent,
}) => (
  <div className="body-two-column ">
    <div className="gr-grid">
      <div className="gr-grid-wrapper mb-0">
        <div className="gr-2-column-wrapper body-left-column v-align-center">
          <div className="gr-2-column gr-2-column-right">
            <div id="section-header" className="header-effect text-align-left">
              <p className="body-label">{label}</p>
              <h3 className="h3">
                {title}
                <br />
                {subtitle}
              </h3>
              <h6 className="h6">{description}</h6>
            </div>
          </div>
          <div className="gr-2-column">
            <picture>{pictures}</picture>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const NarrowBodyRight = ({
  pictures,
  label,
  title,
  subtitle,
  description,
  bodyContent,
}) => (
  <div className="body-two-column ">
    <div className="gr-grid">
      <div className="gr-grid-wrapper mb-0">
        <div className="gr-2-column-wrapper v-align-center">
          <div className="gr-2-column">
            <picture>{pictures}</picture>
          </div>
          <div className="gr-2-column gr-2-column-left">
            <div id="section-header" className="header-effect text-align-left">
              <p className="body-label">{label}</p>
              <h3 className="h3">
                {title}
                <br />
                {subtitle}
              </h3>
              <h6 className="h6">{description}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const MainTwoColumnLeft = React.memo(
  ({ title1, title2, description, subContent, image }) => (
    <div className="_content">
      <div className="hero hero-header">
        <div id="header" className=" left">
          <div className="h1">
            {title1}
            <br />
            {title2}
          </div>
          <div className="h4 mb-line">{description}</div>
          {subContent}
        </div>
        <div className="hero-image-container">
          <picture>{image}</picture>
        </div>
      </div>
    </div>
  )
);

export const EventMainTwoColumnLeft = React.memo(
  ({ title1, title2, description, subContent, image }) => (
    <div id="event" className="_content">
      <div className="hero hero-header">
        <div id="header" className=" left">
          <div className="h1">
            {title1}
            <br />
            {title2}
          </div>
          <div className="h4 mb-line">{description}</div>
          {subContent}
        </div>
        <div className="hero-image-container">
          <picture>{image}</picture>
        </div>
      </div>
    </div>
  )
);

export const SubHeaderTwoColumnLeft = React.memo(
  ({ textArea, imageArea, isRight, hasMargin, isLast }) => (
    <div
      className={`two-column-wrapper ${
        isRight ? "two-column-wrapper--right" : ""
      } ${hasMargin ? "two-column-wrapper--margin-top" : ""} ${
        isLast ? "two-column-wrapper--margin-bottom" : ""
      }`}
    >
      <div className="left-container">{textArea}</div>
      <div className="right-container">{imageArea}</div>
    </div>
  )
);

export const CaseDetail = React.memo(
  ({ title, description, tags, onLinkClick, imageURL, isReverse }) => (
    <div className="case-wrapper">
      <div className="case-block">
        {/* Upper */}
        <div className="upper product-feature">
          <div>
            <div className="h3">{title}</div>
            <p>{description}</p>
          </div>
          <div className="mt-small mb-small">
            {onLinkClick && (
              <a onClick={onLinkClick} className="h4 link-text">
                {`${title} 더 알아보기`}
              </a>
            )}
          </div>
          <div className="d-flex">
            {(tags || []).map((tag, index) => (
              <div
                key={index}
                className={`tag-comp ${
                  index === tags.length - 1 ? "" : "mr-base"
                }`}
              >
                {tag}
              </div>
            ))}
          </div>
        </div>
        {/* Bottom */}
        <div className="bottom">
          <img src={imageURL} className="w-h-100 prevent-image-down" />
        </div>
      </div>
    </div>
  )
);

export const ReverseCaseDetail = React.memo(
  ({ title, description, tags, onLinkClick, imageURL, isReverse }) => (
    <div className="case-wrapper">
      <div className="case-block">
        {/* Upper */}
        <div className="upper product-feature">
          <div>
            <div className="h3 white-color">{title}</div>
            <p className="white-color">{description}</p>
          </div>
          <div className="mt-small mb-small">
            {onLinkClick && (
              <a onClick={onLinkClick} className="h4 link-text">
                {`${title} 더 알아보기`}
              </a>
            )}
          </div>
          <div className="d-flex">
            {tags.map((tag, index) => (
              <div
                key={index}
                className={`tag-comp ${
                  index === tags.length - 1 ? "" : "mr-base"
                }`}
                style={{ backgroundColor: "#fff", color: "#1a238e" }}
              >
                {tag}
              </div>
            ))}
          </div>
        </div>
        {/* Bottom */}
        <div className="bottom">
          <img src={imageURL} className="w-h-100 prevent-image-down" />
        </div>
      </div>
    </div>
  )
);

export const ShortCaseDetail = React.memo(
  ({ title, description, imageURL }) => (
    <div className="case-wrapper">
      <div className="case-block">
        {/* Upper */}
        <div className="upper product-feature">
          <div>
            <div className="h3">{title}</div>
            <p>{description}</p>
          </div>
        </div>
        {/* Bottom */}
        <div className="bottom">
          <img src={imageURL} className="w-h-100 prevent-image-down" />
        </div>
      </div>
    </div>
  )
);

export const MarketingDetail = React.memo(
  ({ imageURL, title, description, subContent }) => (
    <div className="marketing-wrapper">
      <div className="marketing-block">
        <div className="upper">
          <img src={imageURL} className="w-h-100 prevent-image-down" />
        </div>
        <div className="bottom product-feature">
          <div>
            <div className="h4">{title}</div>
            <p>{description}</p>
          </div>
          {subContent}
        </div>
      </div>
    </div>
  )
);

export const TwoWideOne = React.memo(({ twoWideProps, oneWideProps }) => (
  <div className="marketing-container">
    <div className="two-wide-wrapper">
      <div className="two-wide-block">
        <div className="upper">{twoWideProps.imageURL}</div>
        <div className="bottom product-feature">
          <div>
            <div className="h4">{twoWideProps.title}</div>
            <p>{twoWideProps.description}</p>
          </div>
        </div>
      </div>
    </div>
    <div className="one-wide-wrapper">
      <div className="two-wide-block">
        <div className="upper">{oneWideProps.imageURL}</div>
        <div className="bottom product-feature">
          <div>
            <div className="h4">{oneWideProps.title}</div>
            <p>{oneWideProps.description}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
));

export const FeatureDetail = React.memo(({ imageURL, title, subContent }) => (
  <div className="kiosk-features-wrapper">
    <div className="kiosk-feature">
      <div className="upper">
        <img src={imageURL} className="w-h-100 prevent-image-down" />
      </div>
      <div className="bottom product-feature">
        <div className="h4">{title}</div>
        {subContent}
      </div>
    </div>
  </div>
));

export const SupportContent = React.memo(({}) => (
  <div className="main-section">
    <div className="content pb-small">
      <div className="sub-header-wrapper column-center">
        <div className="sub-title text-align-center">
          24/7 언제나 열려있는 고객센터
        </div>
      </div>
    </div>
    <div className="content pt-pb-0">
      <div className="gr-grid">
        <div className="gr-grid-wrapper ">
          <div className="gr-2-column-wrapper v-align-center multi-column-grid">
            <div className="gr-2-column">
              <div
                className="cole-gray-bg p-small"
                style={{ borderRadius: "5px" }}
              >
                <div
                  id="section-header"
                  className="gr-grid-column text-align-center"
                >
                  <div className="support-block">
                    <img
                      className="support-logo"
                      src={require("../img/landing/kakao-chat.png")}
                    />
                  </div>
                  <div className="flex-column" style={{ gap: "10px" }}>
                    <h4 className="h4">{"카카오톡 상담"}</h4>
                    <p>{"'그로잉세일즈 점주센터'를 검색해 주세요!"}</p>
                    <a
                      onClick={() =>
                        buttonTracking("global", "move", "inquiry")
                      }
                      href={Constants.KAKAO}
                      target="_blank"
                      className="h4 link-text"
                    >
                      {"지금 문의하기"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="gr-2-column">
              <div
                className="cole-gray-bg p-small"
                style={{
                  borderRadius: "5px",
                }}
              >
                <div id="section-header" className="gr-grid-column">
                  <div className="support-block">
                    <img
                      className="support-logo"
                      src={require("../img/share/telephone-receiver.png")}
                    />
                  </div>
                  <div className="flex-column" style={{ gap: "10px" }}>
                    <h4 className="h4">{"전화 상담"}</h4>
                    <p>{"문의전화는 오전 9시부터 오후 7시까지!"}</p>
                    <a
                      onClick={() =>
                        buttonTracking("global", "move", "inquiry")
                      }
                      className="h4 link-text-no-arrow"
                    >
                      {"1599-2603"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
));

export const CaseOneColumn = React.memo(
  ({
    title1,
    description,
    label,
    title2,
    imageURL,
    imageStyle,
    labelColor,
  }) => (
    <div className="one-feature-hero">
      <div id="sub-header" style={{ textAlign: "center" }}>
        {label ? (
          <div className="flex-center">
            <div
              className="label-wrapper"
              style={{ backgroundColor: labelColor ? labelColor : "#006aff" }}
            >
              {label}
            </div>
          </div>
        ) : null}
        <div className="h3">
          {title1}
          <br /> {title2}
        </div>
        <div className="h4 mt-line">{description}</div>
      </div>
      <img src={imageURL} className="w-100" style={imageStyle} />
    </div>
  )
);

export const PricingBlock = React.memo(
  ({ subheader, title, desc, button, isWhite }) => (
    <div className="pricing-wrapper">
      <div
        className={
          isWhite ? "pricing-block pricing-block--white" : "pricing-block"
        }
      >
        <div className="h5">{subheader}</div>
        <div className="h3 blue-color">{title}</div>
        {desc}
        <div className="mt-small">{button}</div>
      </div>
    </div>
  )
);

export const QandABlock = React.memo(({ data }) => {
  const styles = {
    bgColor: "transparent",
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentColor: "white",
    arrowColor: "white",
  };
  const config = {};
  return (
    <div>
      <Faq data={data} styles={styles} config={config} />
    </div>
  );
});

export const TabComp = ({ id, title, index, onTabChange }) => (
  <li>
    <a
      class="pt-pb-base"
      aria-controls={`tab-${id}`}
      aria-selected={index === 0 ? "true" : "false"}
      // href={`#${`tab-${id}`}`}
      id={`tab-${id}`}
      role="tab"
      onClick={() => onTabChange(id, index)}
    >
      {title}
    </a>
  </li>
);

export const FeatureBlock = React.memo(({ desc }) => (
  <div className="d-flex align-items-center mt-line mb-base">
    <div className="check-icon" />
    <p className="mb-0 ml-base">{desc}</p>
  </div>
));

export const KioskFeatureBlock = React.memo(({ desc }) => (
  <div className="d-flex align-items-center mt-line mb-base">
    <div className="check-icon" />
    <p className="mb-0 ml-base">{desc}</p>
  </div>
));
